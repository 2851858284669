import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { forwardRef, useImperativeHandle } from 'react';

const VerifyOtp = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
        handleShow() {
            handleShow();
        },
        handleClose() {
            handleClose();
        }
    }));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{props.label}
                            </Form.Label>
                            <Form.Control
                                type="phone"
                                placeholder={props.placeholder}
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
                    <Button variant="primary" onClick={props.handleVerify}>
                        {props.button}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
})

export default VerifyOtp;
