import React from "react";
import Form from 'react-bootstrap/Form';

function Beneficiary() {
    return (<div className="row">
        <label >Beneficiaries 1</label>
        <div className="col-4">
            <label className="mb-2" htmlFor="name">Name</label>
            <input className="form-control mb-30" id="name" type="text" name="name" placeholder="Name" required />
        </div>
        <div className="col-3">
            <label className="mb-2" htmlFor="age">Age</label>
            <input className="form-control mb-30" id="age" name="age" type="number" placeholder="Age" required />
        </div>
        <div className="col-5">
            <label className="mb-2" htmlFor="country">Gender</label>
            <div className="row">
                <div className="col-6">
                    <Form.Check
                        inline
                        label="Male"
                        name="group1"
                        type="radio"
                        id="male"

                    />
                </div>
                <div className="col-6">
                    <Form.Check
                        inline
                        label="Female"
                        name="group1"
                        type="radio"
                        id="female"
                        className="col-6"
                    />
                </div>
            </div>
        </div>
    </div>);
}

export default Beneficiary;