import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import BreadcrumbOne from "../../components/breadcrumb/BreadcrumbOne";
import Footer from "../../components/footer/Footer";
import Divider from "../../components/divider/Divider";
// import SignInviaOthers from "../../components/register/SignInVia";
import SEO from "../../components/common/SEO";
import VerifyOtp from '../../components/Modal/VerifyOtp';
import NewPassword from '../../components/Modal/NewPassword';

export default function ForgetPassword() {
    const verifyOTPRef = useRef(null);
    const newPasswordRef = useRef(null);

    const handleClick = () => {
        verifyOTPRef.current.handleShow();
    };

    const handleVerify = () => {
        newPasswordRef.current.handleNewPasswordShow();
        verifyOTPRef.current.handleClose();
    };

    return (
        <div>
            <VerifyOtp ref={verifyOTPRef}
                heading="Verify OTP"
                label="OTP"
                placeholder="Enter OTP"
                button="Send OTP"
                handleVerify={handleVerify}
            />

            <NewPassword ref={newPasswordRef}
                heading="Reset New Password"
                label="New Password"
                placeholder="New Password"
                button="Reset Password"
                // handleVerify={handleVerify}
            />

            <SEO title="Verdure Way | Forget Password" />

            <Header
                brandLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                headerStyle="header-2"
                buttonText="Login"
                buttonColor="btn-primary"
            />


            <BreadcrumbOne
                breadcrumbImage="assets/img/bg-img/7.jpg"
                breadcrumbTitle="Forget Password"
                homePageUrl="/"
                homePageText="Home"
                currentPageText="Forget"
            />

            <Divider />

            <div className="register-area">
                <div className="container">
                    <div className="row g-5 align-items-center justify-content-between">
                        <div className="col-12 col-lg-5">
                            <div className="register-thumbnail">
                                <img src="assets/img/illustrator/hero-2.png" alt="" />
                            </div>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="card register-card bg-gray p-1 p-sm-4">
                                <div className="card-body">
                                    <h4>Reset Password</h4>
                                    <p>Already have an account?
                                        <Link className="ms-2 fw-bold" to="/login">Log In</Link>
                                    </p>

                                    {/* Forget Password Form */}
                                    <div className="register-form my-5">
                                        <form onSubmit={e => { e.preventDefault(); }} >
                                            <div className="form-group mb-3 col-12 col-sm-10 col-md-12 col-lg-12">
                                                <input className="form-control" type="phone" name="mobileNo" placeholder="Mobile No" required />
                                            </div>
                                            <button className="btn btn-primary w-100" type="submit" onClick={handleClick}>
                                                <i className="bi bi-lock me-2" />Reset Password
                                            </button>
                                        </form>
                                    </div>

                                    {/* Sign in via others */}
                                    {/* <SignInviaOthers /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Divider />

            <Footer
                footerLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                footerStyle="footer-2"
            />
        </div>
    )
}