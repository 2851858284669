import { useState } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import SaasboxNav from "./Nav";
import useStickyHeader from "./StickyHeader";
import Cart from "../popover/Cart";

export default function Header(props) {
    const { brandLogo, headerStyle, buttonText, buttonColor } = props;

    let [check] = useState(true);
    const sticky = useStickyHeader(10);
    const stickyClass = `${(sticky && check) ? 'sticky' : ''}`

    return (
        <header className={`header-area ${headerStyle} ${stickyClass}`}>
            <Navbar expand="lg">
                <div className="container">
                    {/* Navbar Brand */}
                    <Link className="navbar-brand" to="/">
                        <img src={`${process.env.PUBLIC_URL}/${brandLogo}`} alt="Verdure Logo" style={{ width: '200px', padding: '15px' }} />
                    </Link>

                    {/* Navbar Toggler */}
                    <Navbar.Toggle className="navbar-toggler" aria-controls="saasboxNav" />

                    <Navbar.Collapse id="saasboxNav">
                        {/* Nav */}
                        <SaasboxNav />

                        {/* Button */}
                        <div className="ms-auto mb-3 mb-lg-0">
                            <Link className={`btn ${buttonColor} btn-sm`} to="/login">
                                {buttonText}
                            </Link>

                            <Cart buttonText={buttonText} />

                            {/* <Link className={`btn ${buttonColor} btn-sm ms-2`} to="/cart">
                                <i className="bi bi-cart" />
                            </Link> */}
                        </div>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </header>
    );
}