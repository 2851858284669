import React from 'react';

import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

// All CSS Import
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/all-css-libraries.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';

// Home Page
// import DemoPage from './pages/Index';
import Home from './pages/new/Home';
import Home1 from './pages/Home1';
import SaasLanding from './pages/SaasLanding';
import SeoBusiness from './pages/SeoBusiness';

// Portfolio Page
import PortfolioStandard from "./pages/PortfolioStandard";
import PortfolioCreative from "./pages/PortfolioCreative";
import PortfolioFullWidth from "./pages/PortfolioFullWidth";
import PortfolioDetails from "./pages/PortfolioDetails";
import PortfolioDetailsTwo from "./pages/PortfolioDetailsTwo";

// Shop Page
import Cart from "./pages/new/Cart";
import Checkout from "./pages/new/Checkout";

import ShopFullWidth from "./pages/ShopFullWidth";
import ShopSidebar from "./pages/ShopSidebar";
import ProductDetails from "./pages/SingleProduct";
import CartOld from "./pages/Cart";
import CheckoutOld from "./pages/Checkout";

// Blog Page
import BlogOne from "./pages/BlogOne";
import BlogTwo from "./pages/BlogTwo";
import BlogThree from "./pages/BlogThree";
import BlogDetailsOne from "./pages/BlogDetailsOne";
import BlogDetailsTwo from "./pages/BlogDetailsTwo";
import BlogDetails from "./components/blog/BlogDetails";

// Others Page
import AllTeamMembers from "./pages/Team";
import NotFound from "./pages/NotFound";
import AboutStandard from "./pages/AboutStandard";
import AboutCreative from "./pages/AboutCreative";
import ServiceStandard from "./pages/ServiceStandard";
import ServiceCreative from "./pages/ServiceCreative";
import PricingPlan from "./pages/PricingPlan";
import FAQ from "./pages/Faq";
import Contact from "./pages/new/Contact";
import Reviews from "./pages/Reviews";
import ComingSoon from "./pages/ComingSoon";
import NewsletterPage from "./pages/Newsletter";
import Register from "./pages/new/Register";
import Login from "./pages/new/Login";
import ForgetPassword from "./pages/new/ForgetPassword";
import Services from './pages/new/Services';
import About from './pages/new/About';

export default function App() {
  return (
    <div className="App">
      <Routes>
        {/* <BrowserRouter basename={`${process.env.PUBLIC_URL}`} /> */}
        <Route path={`${process.env.PUBLIC_URL}/`} index element={<Home />} />
        <Route path={`${process.env.PUBLIC_URL}/about`} element={<About />} />
        <Route path={`${process.env.PUBLIC_URL}/services`} element={<Services />} />
        <Route path={`${process.env.PUBLIC_URL}/cart`} element={<Cart />} />
        <Route path={`${process.env.PUBLIC_URL}/checkout`} element={<Checkout />} />

        

        <Route path={`${process.env.PUBLIC_URL}/creative-agency`} element={<Home1 />} />
        {/* <Route path={`${process.env.PUBLIC_URL}/business-studio`} element={<BusinessStudio />} /> */}
        <Route path={`${process.env.PUBLIC_URL}/saas-landing`} element={<SaasLanding />} />
        <Route path={`${process.env.PUBLIC_URL}/seo-business`} element={<SeoBusiness />} />

        <Route path={`${process.env.PUBLIC_URL}/portfolio-standard`} element={<PortfolioStandard />} />
        <Route path={`${process.env.PUBLIC_URL}/portfolio-creative`} element={<PortfolioCreative />} />
        <Route path={`${process.env.PUBLIC_URL}/portfolio-full-width`} element={<PortfolioFullWidth />} />
        <Route path={`${process.env.PUBLIC_URL}/portfolio-details/:portfolioId`} element={<PortfolioDetails />} />
        <Route path={`${process.env.PUBLIC_URL}/portfolio-details2/:portfolioId`} element={<PortfolioDetailsTwo />} />

        <Route path={`${process.env.PUBLIC_URL}/shop-fullwidth`} element={<ShopFullWidth />} />
        <Route path={`${process.env.PUBLIC_URL}/shop-sidebar`} element={<ShopSidebar />} />
        <Route path={`${process.env.PUBLIC_URL}/product-details/:productId`} element={<ProductDetails />} />
        <Route path={`${process.env.PUBLIC_URL}/cart-old`} element={<CartOld />} />
        <Route path={`${process.env.PUBLIC_URL}/checkout-old`} element={<CheckoutOld />} />

        <Route path={`${process.env.PUBLIC_URL}/blog-1`} element={<BlogOne />} />
        <Route path={`${process.env.PUBLIC_URL}/blog-2`} element={<BlogTwo />} />
        <Route path={`${process.env.PUBLIC_URL}/blog-3`} element={<BlogThree />} />
        <Route path={`${process.env.PUBLIC_URL}/blog-details-1`} element={<BlogDetailsOne />} />
        <Route path={`${process.env.PUBLIC_URL}/blog-details-2`} element={<BlogDetailsTwo />} />
        <Route path={`${process.env.PUBLIC_URL}/blog-details/:postId`} element={<BlogDetails />} />

        <Route path={`${process.env.PUBLIC_URL}/about-standard`} element={<AboutStandard />} />
        <Route path={`${process.env.PUBLIC_URL}/about-creative`} element={<AboutCreative />} />
        <Route path={`${process.env.PUBLIC_URL}/service-standard`} element={<ServiceStandard />} />
        <Route path={`${process.env.PUBLIC_URL}/service-creative`} element={<ServiceCreative />} />

        <Route path={`${process.env.PUBLIC_URL}/team`} element={<AllTeamMembers />} />
        <Route path={`${process.env.PUBLIC_URL}/pricing-plan`} element={<PricingPlan />} />
        <Route path={`${process.env.PUBLIC_URL}/faq`} element={<FAQ />} />
        <Route path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} />
        <Route path={`${process.env.PUBLIC_URL}/reviews`} element={<Reviews />} />
        <Route path={`${process.env.PUBLIC_URL}/coming-soon`} element={<ComingSoon />} />
        <Route path={`${process.env.PUBLIC_URL}/newsletter`} element={<NewsletterPage />} />
        <Route path={`${process.env.PUBLIC_URL}/register`} element={<Register />} />
        <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
        <Route path={`${process.env.PUBLIC_URL}/forget-password`} element={<ForgetPassword />} />

        <Route path={`${process.env.PUBLIC_URL}/not-found`} element={<NotFound />} />
        <Route path="*" element={<Navigate to={`${process.env.PUBLIC_URL}/not-found`} /> } />
      </Routes>

      <ScrollToTop id="scrollTopButton" color="white" smooth top={200} />
    </div>
  );
}