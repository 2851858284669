import React from 'react';

import { Link } from "react-router-dom";
import Divider from "../divider/Divider";

export default function AboutTwo() {
    const aboutImage = "assets/img/bg-img/about_home.jpg";

    const aboutItem = [
        {
            "icon": "assets/img/icons/network.png",
            "title": "Our Network",
            "para": "Verdure Way boasts of extensive networks of authorized, fully trained and well-equipped collection centers."
        },
        {
            "icon": "assets/img/icons/cost.png",
            "title": "Cost",
            "para": "Verdure Way operates with a solitary focus on generating volumes, as it specializes on B2B operations."
        },
        {
            "icon": "assets/img/icons/quality.png",
            "title": "Quality",
            "para": "Best of the Global brands, Best of their instruments, Best of the technologies, Best of the reagents."
        },
        {
            "icon": "assets/img/icons/speed.png",
            "title": "Speed",
            "para": "Laboratory functions 24X7. Air-cargo functions 24 X 7 and 80% of the business is air-cargo driven."
        }
    ]

    const rightSideText = [
        {
            "subtitle": "About Us",
            "title": "Welcome to Verdure Way",
            "para": "Verdure Way envisions ‘life- free of disease’, that too our way- the Verdure Way! We wish you be in pink of health forever and that ‘forever’ starts with being vigilant. That’s why we bring you an array of diagnostic tests from the comfort of your home. With growing work pressure and umpteen responsibilities, our health becomes the least priority."
        },
        {
            "btnColor": "btn-info",
            "btnUrl": "/about",
            "btnText": "Know More"
        }
    ]

    const workSteps = [
        {
            "id": "1",
            "title": "Our Mission",
            "para": "To provide technically sound laboratories systems that can help us to give best of medical services to our clients at reasonable cost."
        },
        {
            "id": "2",
            "title": "Our Vision",
            "para": "Thrusting to provide easy, fast, accurate and technically updated and fully automated diagnostic laboratory system online."
        }
    ]

    const topItems = aboutItem.map((ele, ind) =>
        <div key={ind} className="col-12 col-sm-6 col-lg-3">
            <div className="single-about-item text-center">
                <div className="icon mx-auto mb-4">
                    <img src={`${process.env.PUBLIC_URL}/${ele.icon}`} alt={ele.title} />
                </div>
                <h5 className="mb-3">{ele.title}</h5>
                <p className="mb-0">{ele.para}</p>
            </div>
        </div>
    )

    const workStepsItem = workSteps.map((item, index) =>
        <div key={index} className="single-work-step d-flex mb-4">
            <span>{item.id}</span>
            <div className="work-step-text">
                <h6>{item.title}</h6>
                <p>{item.para}</p>
            </div>
        </div>
    )

    return (
        <div className="about-area">
            <div className="container">
                <div className="about-content">
                    <div className="row justify-content-center g-5 g-lg-4">
                        {topItems}
                    </div>
                </div>
            </div>

            <Divider />

            <div className="container">
                <div className="row g-5 align-items-center justify-content-md-center justify-content-lg-between">
                    {/* About Us Thumbnail */}
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="aboutUs-thumbnail">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${aboutImage}`} alt="" />
                        </div>
                    </div>

                    {/* About Us Content */}
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="aboutUs-content">
                            <div className="section-heading mb-5">
                                <h6>{rightSideText[0].subtitle}</h6>
                                <h2>{rightSideText[0].title}</h2>
                                <p>{rightSideText[0].para}</p>
                            </div>

                            <div className="work-step-wrapper">
                                {workStepsItem}
                            </div>

                            <Link className={`btn ${rightSideText[1].btnColor}`} to={rightSideText[1].btnUrl} >
                                {rightSideText[1].btnText}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}