import React from 'react';
import { Link } from "react-router-dom";

export default function TableRow(props) {
    const { closeIcon, productName, totalPrice } = props;

    return (
        <tr>
            <td>
                <Link to="/product-details/1">{productName}</Link>
            </td>
            <td>{totalPrice}</td>
            <th scope="row">
            <a href="#"><i className={`bi ${closeIcon}`} /></a>
            </th>
        </tr>
    )
}