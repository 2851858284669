import React, { useState } from 'react';

import Header from "../../components/header/Header";
import BreadcrumbTwo from "../../components/breadcrumb/BreadcrumbTwo";
import Divider from "../../components/divider/Divider";
import Footer from "../../components/footer/Footer";
import SEO from "../../components/common/SEO";
import Beneficiary from '../../components/checkout/Beneficiary';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Checkout() {

    const [noOfBeneficiaries, setNoOfBeneficiaries] = useState(1);
    const [startDate, setStartDate] = useState(new Date());

    const [formData, setFormData] = useState({
        noOfBeneficiaries: { value: 1, error: "" },
        name: { value: "", error: "" },
        age: { value: "", error: "" },
        gender: { value: "", error: "" },
        mobile_number: { value: "", error: "" },
        email: { value: "", error: "" },
        postal_code: { value: "", error: "" },
        address: { value: "", error: "" },
        city: { value: "", error: "" },
        state: { value: "", error: "" },
        remark: { value: "", error: "" },
        appointment_date: { value: "", error: "" },
        slot: { value: "", error: "" },
        reportHardCopy: { value: "", error: "" },
        otp_data: {},
    });

    const handleInputChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: {
                value,
                // error: validateFields(label, value),
            },
        });
    };

    const handleBeneficiaries = () => {
        console.log('====================================');
        console.log(formData.noOfBeneficiaries);
        console.log('====================================');
        for (let index = 0; index <= formData.noOfBeneficiaries.value; index++) {
            return <Beneficiary />;
        }
    }

    return (
        <div>
            <SEO title="Verdure Way | Checkout" />

            <Header
                brandLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                headerStyle="header-2"
                buttonText="Login"
                buttonColor="btn-primary"
            />

            <BreadcrumbTwo
                breadcrumbTitle="Checkout"
                homePageUrl="/"
                homePageText="Home"
                currentPageText="Checkout"
            />

            <Divider />

            <div className="checkout-area">
                <div className="container">
                    <div className="row g-5">
                        {/* Checkout Details Area*/}
                        <div className="col-12 col-lg-6">
                            <div className="checkout-details-area">
                                <h3 className="mb-4">Billing Details</h3>

                                <form >
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="mb-2" htmlFor="country">No of Beneficiaries</label>
                                            <select className="form-select form-control w-100 mb-30" id="beneficiary" name="noOfBeneficiaries" value={formData.noOfBeneficiaries.value} onChange={handleInputChange}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>

                                        {handleBeneficiaries()}

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="mobile">Mobile No</label>
                                            <input className="form-control mb-30" id="mobile" type="phone" name="mobile_number" placeholder="Mobile No" />
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="email-address">Email ID</label>
                                            <input className="form-control mb-30" id="email-address" type="email" name="email" placeholder="Email ID" required />
                                        </div>

                                        <div className="col-12">
                                            <label className="mb-2" htmlFor="address">Address</label>
                                            <input className="form-control mb-30" id="address" type="text" name="address" placeholder="Address" />
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="city">Town/City</label>
                                            <input className="form-control mb-30" id="city" type="text" name="city" placeholder="Town/City" />
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="state">State</label>
                                            <input className="form-control mb-30" id="state" type="text" name="state" placeholder="State" />
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="postcode">Postcode/Zip</label>
                                            <input className="form-control mb-30" id="postcode" type="text" name="postal_code" placeholder="Postcode/Zip" />
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="Remark">Remark</label>
                                            <textarea className="form-control" id="Remark" name="reamrk" placeholder="Remark" rows={1} />
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="country">Appointment Date</label>
                                            <DatePicker className="form-control mb-30" selected={startDate} onChange={(date) => setStartDate(date)}       
                                            />
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="country">Select Time Slot</label>
                                            <select className="form-select form-control w-100 mb-30" id="beneficiary" name="noOfBeneficiaries" value={formData.noOfBeneficiaries.value} onChange={handleInputChange}>
                                                <option value="1">10:00AM - 10:30AM</option>
                                            </select>
                                        </div>

                                        <div className="col-6">
                                            <label className="mb-2" htmlFor="hardCopy">Want hard copy of report?</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group1"
                                                        type="radio"
                                                        id="yes"

                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group1"
                                                        type="radio"
                                                        id="no"
                                                        className="col-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* Order Form Area*/}
                        <div className="col-12 col-lg-6">
                            <div className="order-form bg-gray">
                                <h4 className="mb-4">Your Order(s)</h4>

                                {/* Order Table*/}
                                <div className="order-table table-responsive mb-4">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="px-3">Product</th>
                                                <th className="text-end px-3">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderTop: 0 }} >
                                            <tr>
                                                <th className="px-3">Bonsai Tree
                                                    <span> - 1 p</span>
                                                </th>
                                                <td className="text-end px-3">$9.63</td>
                                            </tr>
                                            <tr>
                                                <th className="px-3">Sony BS4
                                                    <span> - 1 p</span>
                                                </th>
                                                <td className="text-end px-3">$7.31</td>
                                            </tr>
                                        </tbody>
                                        <tfoot style={{ borderTop: 0 }} >
                                            <tr>
                                                <th className="px-3">Subtotal </th>
                                                <td className="text-end px-3">$16.94</td>
                                            </tr>
                                            <tr>
                                                <th className="px-3">Shipping </th>
                                                <td className="text-end px-3">Flat Rate $5.00</td>
                                            </tr>
                                            <tr>
                                                <th className="px-3">Order Total </th>
                                                <td className="text-end px-3">$21.94</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                                {/* Checkout Payment */}
                                <div className="checkout-payment-area">
                                    <form onSubmit={e => { e.preventDefault(); }} >
                                        <div className="form-group mb-3">
                                            <div className="form-check">
                                                <input className="form-check-input" id="bank" type="radio" defaultValue="bank" name="checkout-payment" defaultChecked />
                                                <label className="form-check-label font-weight-bold" htmlFor="bank">
                                                    Direct Bank Transfer
                                                    <br />
                                                    Make your payment directly into our bank account.
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <div className="form-check">
                                                <input className="form-check-input" id="cheque" type="radio" defaultValue="cheque" name="checkout-payment" />

                                                <label className="form-check-label font-weight-bold" htmlFor="cheque">
                                                    Cheque Payments
                                                    <br />
                                                    Please send a check to Store Name, Store Street, Store Town, Store State/County, Store Postcode.
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <div className="form-check">
                                                <input className="form-check-input" id="cash" type="radio" defaultValue="cash" name="checkout-payment" />
                                                <label className="form-check-label font-weight-bold" htmlFor="cash">
                                                    Cash On Delivery
                                                    <br />
                                                    Pay with cash upon delivery.
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-check">
                                                <input className="form-check-input" id="paypal" type="radio" defaultValue="paypal" name="checkout-payment" />
                                                <label className="form-check-label font-weight-bold" htmlFor="paypal">
                                                    Payment via PayPal
                                                    <br />
                                                    Pay with PayPal.
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group mt-4">
                                            <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                                        </div>

                                        {/* Place Order*/}
                                        <button className="btn btn-primary w-100 mt-4" type="submit">Place Order</button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Divider />

            <Footer
                footerLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                footerStyle="footer-2"
            />
        </div>
    )
}