import React from 'react';

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import AboutTwo from "../../components/about/AboutTwo";
import Divider from "../../components/divider/Divider";
import HeroTwo from "../../components/hero/HeroTwo";
import Newsletter from "../../components/newsletter/Newsletter";
import SEO from "../../components/common/SEO";
import PartnerOne from '../../components/partner/PartnerOne';
import Offer from '../../components/offer/Offer';
import Package from '../../components/package/Package';

export default function Home() {
    return (
        <div className="business-studio-wrap">
            <SEO title="Verdure Way | Home" />

            <Header
                brandLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                headerStyle="header-2"
                buttonText="Login"
                buttonColor="btn-primary"
            />

            <HeroTwo
                BgShapeImage="assets/img/core-img/hero-2.png"
                title="We focus on the growth of your business."
                subtitle="It's crafted with the latest trend of design &amp; coded with all modern approaches. It's a robust &amp; multi-dimensional usable template."
                buttons={[
                    {
                        btnColor: "btn-warning",
                        btnUrl: "/",
                        btnText: "Get Started"
                    },
                    {
                        btnColor: "btn-danger",
                        btnUrl: "lFGvqvPh5jI",
                        btnText: "Play Video"
                    }
                ]}
                heroThumb="assets/img/illustrator/Verdureway_homepage_vector.png"
            />

            <div className="container">
                <div className="border-top"></div>
            </div>

            <Divider />

            {/* About Us */}
            <AboutTwo />

            <Divider />

            {/* Offer Section */}
            <Offer />

            <Divider />

            {/* Associate with Section */}
            <PartnerOne />

            <Divider />

            {/* <Packages /> */}
            <Package />

            <Divider />

            {/* <PricingTableTwo /> */}

            {/* <Divider /> */}


            {/* <TestimonialTwo /> */}

            {/* <BlogTwo /> */}

            {/* <Divider /> */}

            <Newsletter
                backgroundImage="assets/img/core-img/4.png"
                title="Subscribe to our newsletter"
                subtitle="Subscribe to our newsletter, we will notify you when we are live."
                placeholderText="Type your mail"
                inputSubId="mailHelp"
                inputSubText="We'll never share your email with anyone else."
                btnColor="btn-primary"
                btnText="Subscribe Now"
            />

            <Divider />

            <Footer
                footerLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                footerStyle="footer-2"
            />
        </div>
    )
}