import React from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function SEO(props) {
    const { title } = props;

    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
            </Helmet>
        </HelmetProvider>
    )
}