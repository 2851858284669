import React from 'react';
import { Link } from "react-router-dom";

export default function FooterAbout(props) {
    const { footerText, heading } = props;

    return (
        <div className="col-12 col-sm-6 col-lg-3">
            {/* Footer Widget */}
            <div className="footer-widget-area">
                <h5 className="mb-4">{heading}</h5>
                <p>{footerText}</p>
            </div>
        </div>
    )
}