import React from 'react';

import { Link } from 'react-router-dom';
import ScrollAnimation from "react-animate-on-scroll";

export default function HeroTwo(props) {
    const { BgShapeImage, title, subtitle, buttons, heroThumb } = props;

    return (
        <div className="welcome-area hero2 bg-white">
            {/* Shape */}
            <div className="hero-background-shape">
                <img src={`${process.env.PUBLIC_URL}/${BgShapeImage}`} alt={title} />
            </div>

            {/* Animation */}
            <div className="background-animation">
                <div className="item1" />
                <div className="item4" />
                <div className="item5" />
            </div>

            <div className="hero2-big-circle" />

            <div className="container h-100">
                <div className="row h-100 align-items-center justify-content-between">
                    {/* Welcome Content */}
                    <div className="col-12 col-sm-10 col-md-12 col-lg-6">
                        <div className="welcome-content mt-0">
                            <h1 className="mb-0 ps-1 d-flex flex-wrap align-items-center mb-5 justify-content-center">
                                Book Your Test
                            </h1>
                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={0} animateOnce={true}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    {/* Feature Card */}
                                    <div className="card feature-card">
                                        <div className="card-body d-flex align-items-center flex-wrap" style={{ backgroundColor: '#f4f4ff' }}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <form >
                                                    <div className="row">

                                                        <div className="col-sm-6 col-md-6">
                                                            {/* <label className="mb-2" htmlFor="country">Test Name</label> */}
                                                            <select className="form-select form-control w-100 mb-30" id="country">
                                                                <option value="usa">Select Test</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-sm-6 col-md-3">
                                                            {/* <label className="mb-2" htmlFor="price">Price</label> */}
                                                            <input className="form-control mb-30" id="price" type="text" placeholder="Price" required />
                                                        </div>

                                                        <div className="col-sm-6 col-md-3">
                                                            <button className="btn btn-primary w-100 mb-30" type="submit">Book</button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                                <div className="line mb-30"></div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <form >
                                                    <div className="row">

                                                        <div className="col-sm-6 col-md-6">
                                                            {/* <label className="mb-2" htmlFor="country">Test Name</label> */}
                                                            <select className="form-select form-control w-100 mb-30 mb-sm-0" id="recommendedTest">
                                                                <option value="usa">Recommended Test</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-sm-6 col-md-3">
                                                            {/* <label className="mb-2" htmlFor="price">Price</label> */}
                                                            <input className="form-control mb-30 mb-sm-0" id="price" type="text" placeholder="Price" required />
                                                        </div>

                                                        <div className="col-sm-6 col-md-3">
                                                            <button className="btn btn-primary w-100" type="submit">Book</button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    {/* Welcome Thumb */}
                    <div className="col-12 col-sm-10 col-md-12 col-lg-6">
                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" delay={200} animateOnce={true}>
                            <div className="welcome-thumb ms-xl-5 mt-0">
                                <img src={`${process.env.PUBLIC_URL}/${heroThumb}`} alt={title} />
                                <h2 className="mb-0 ps-1 d-flex flex-wrap align-items-center mt-5 justify-content-center">
                                    <div className="pink-color">Laboratory Test !!! </div>
                                    <div>It's Now So Easy and Handy!!!</div>
                                </h2>
                            </div>

                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    )
}