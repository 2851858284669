import React from 'react';

import SectionHeading from "../heading/HeadingOne";
import PartnerOneLogo from "./PartnerOneLogo";

export default function PartnerOne() {
    return (
        <div className="partner-area">

            <SectionHeading
                heading="We are associate with"
            />

            <div className="container">
                {/* Partner Wrapper */}
                <div className="partner-wrapper">
                    {/* Logo */}
                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/LOGO_thyrocare.png"
                    />

                    {/* <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/2.png"
                    />

                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/3.png"
                    />

                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/4.png"
                    />

                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/5.png"
                    />

                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/6.png"
                    />

                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/1.png"
                    />

                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/2.png"
                    />

                    <PartnerOneLogo
                        partnerLogo="assets/img/partner-img/3.png"
                    /> */}
                </div>
            </div>
        </div>
    )
}