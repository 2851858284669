import React from 'react';
import { Link } from "react-router-dom";

export default function CartRow(props) {
    const { closeIcon, productName, totalPrice } = props;

    return (
        <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                {/* Cart Total Area*/}
                <div className="cart-total-area ms-auto">
                    <div className="table-responsive">
                        <table className="table mb-4 table-bordered">
                            <tbody >
                                <tr>
                                    <td className="px-3">{productName}</td>
                                    <td className="px-3">{totalPrice}</td>
                                    <td className="px-3">
                                        <a href="#"><i className={`bi ${closeIcon}`} /></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-3">{productName}</td>
                                    <td className="px-3">{totalPrice}</td>
                                    <td className="px-3">
                                        <a href="#"><i className={`bi ${closeIcon}`} /></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-3">{productName}</td>
                                    <td className="px-3">{totalPrice}</td>
                                    <td className="px-3">
                                        <a href="#"><i className={`bi ${closeIcon}`} /></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Link className="btn btn-primary w-100 mb-2" to="/cart">Cart</Link>
                    <Link className="btn btn-primary w-100" to="/checkout">Proceed To Checkout</Link>
                </div>
            </div>

        </div>
    )
}