import React from 'react';
import FooterWidgetOne from "./FooterWidgetOne";
import FooterWidgetNav from "./FooterWidgetNav";
import FooterWidgetContact from "./FooterWidgetContact";
import FooterCopywrite from "./FooterCopywrite";
import FooterAbout from './FooterAbout';

export default function Footer(props) {
    const { footerLogo, footerStyle } = props;

    return (
        <footer className={`footer-area ${footerStyle} pt-120 pb-120`}>
            <div className="container">
                <div className="row g-4 g-lg-5">

                    {/* Footer Widget: src > components > footer > FooterWidgetOne */}
                    <FooterWidgetOne
                        footerLogo={footerLogo}
                        newsletterPlaceholderText="Enter email"
                    />

                    {/* Footer About: src > components > footer > FooterWidgetOne */}
                    <FooterAbout 
                        heading="About Us"
                        footerText="Verdure Way envisions ‘life- free of disease’, that too our way- the Verdure Way! We wish you be in pink of health forever and that ‘forever’ starts with being vigilant."
                    />

                    {/* Footer Widget: src > components > footer > FooterWidgetNav */}
                    {/* <FooterWidgetNav
                        heading="About Us"

                        footerNav={[
                            {
                                title: "Suha Mobile",
                                url: "#"
                            },
                            {
                                title: "Affan Mobile",
                                url: "#"
                            },
                            {
                                title: "Newsten Blog",
                                url: "#"
                            },
                            {
                                title: "Classy Multipurpose",
                                url: "#"
                            },
                            {
                                title: "Educamp Education",
                                url: "#"
                            }
                        ]}
                    /> */}

                    {/* Footer Widget: src > components > footer > FooterWidgetNav */}
                    <FooterWidgetNav
                        heading="Important Links"
                        footerNav={[
                            {
                                title: "Home",
                                url: "/"
                            },
                            {
                                title: "About",
                                url: "/about"
                            },
                            {
                                title: "Services",
                                url: "/services"
                            },
                            {
                                title: "Order",
                                url: "#"
                            },
                            {
                                title: "Contact",
                                url: "/contact"
                            }
                        ]}
                    />

                    {/* Footer Widget: src > components > footer > FooterWidgetContact */}
                    <FooterWidgetContact
                        heading="Stay With Us"
                        phone="+91 70656 00046"
                        email="care@verdureway.com"
                        time="10:00AM to 5:00PM"
                        social={[
                            {
                                url: "https://facebook.com",
                                tootipPosition: "top",
                                title: "Facebook",
                                iconName: "bi-facebook"
                            },
                            {
                                url: "https://twitter.com",
                                tootipPosition: "top",
                                title: "Twitter",
                                iconName: "bi-twitter"
                            },
                            {
                                url: "https://instagram.com",
                                tootipPosition: "top",
                                title: "Instagram",
                                iconName: "bi-instagram"
                            },
                            {
                                url: "https://linkedin.com",
                                tootipPosition: "top",
                                title: "Linkedin",
                                iconName: "bi-linkedin"
                            },
                            {
                                url: "https://youtube.com",
                                tootipPosition: "top",
                                title: "YouTube",
                                iconName: "bi-youtube"
                            }
                        ]}
                    />

                </div>
            </div>

            {/* Footer Copywrite: src > components > footer > FooterCopywrite */}
            <FooterCopywrite
                footerNavTwo={[
                    {
                        title: "Make an Enquiry",
                        url: "#"
                    },
                    {
                        title: "Book an Appointment",
                        url: "/contact"
                    },
                    {
                        title: "Career",
                        url: "/contact"
                    },
                    {
                        title: "Partner With Us",
                        url: "/contact"
                    },
                    {
                        title: "Terms & Conditions",
                        url: "#"
                    }
                ]}
            />

        </footer>
    );
}