import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CartRow from '../cart/CartRow';

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3" className="d-flex justify-content-space-between">
            <span className="col-lg-6"><i className="bi bi-cart" /></span>
            <span className="col-lg-6">Total: ₹1000</span>
        </Popover.Header>
        <Popover.Body className="d-flex justify-content-space-between">
            <CartRow
                closeIcon="bi-x-lg"
                productName="Sony PS4"
                totalPrice="$1.11"
            />
        </Popover.Body>
    </Popover>
);

export default function Cart(props) {
    return (
        <OverlayTrigger trigger="click" rootClose placement="auto" overlay={popover}>
            {/* <Button variant="success btn-sm ms-2"><i className="bi bi-cart" /></Button> */}
            <Button className={`btn ${props.buttonColor} btn-sm ms-2`} id="cart-popup-id">
                <span className="p1 fa-stack fa-2x has-badge" data-count="4" >
                    <i className="bi bi-cart" />
                </span>
            </Button>
        </OverlayTrigger>
    )
};

