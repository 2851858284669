import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import BreadcrumbTwo from "../../components/breadcrumb/BreadcrumbTwo";
import Divider from "../../components/divider/Divider";
import Footer from "../../components/footer/Footer";
import ProductCard from "../../components/shop/ProductCard";
import ShopData from "../../data/shop/shop-data.json";
import SEO from "../../components/common/SEO";
import SelectDropdown from '../../components/dropdown/SelectDropdown';

export default function Services() {
    const [disease, setDisease] = useState(["Liver", "Thyroid"]);

    const shopLists = ShopData.map((elem, index) => (
        <ProductCard
            productColumn="col-12 col-md-4"
            key={index}
            id={elem.id}
            productImage={elem.productImage}
            favIcon={elem.favIcon}
            productName={elem.productName}
            salePrice={elem.salePrice}
            oldPrice={elem.oldPrice}
            addToCartIcon={elem.addToCartIcon}
        />
    ))

    return (
        <div>
            <SEO title="Verdure Way | Sevices" />

            <Header
                brandLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                headerStyle="header-2"
                buttonText="Login"
                buttonColor="btn-primary"
            />

            <BreadcrumbTwo
                breadcrumbTitle="Book Your Test"
                homePageUrl="/"
                homePageText="Home"
                currentPageText="Services"
            />

            <Divider />

            {/* <ShopMeta /> */}

            <div className="shop-with-sidebar">
                <div className="container">
                    {/* <div className="row"> */}
                    {/* <div className="col-12 col-sm-12 col-md-12"> */}
                    <div className="shop-sidebar-area row">

                        {/* Single Widget*/}
                        <div className="shop-widget mb-4 mb-lg-5 col-12 col-sm-12 col-md-6">
                            {/* <h5 className="widget-title mb-4">Service Categories</h5> */}
                            {/* Description*/}
                            <div className="widget-desc d-flex justify-content-between">
                                {/* Single Checkbox*/}
                                <div className="form-check mb-2">
                                    <input className="form-check-input" id="customCheck1" type="checkbox" defaultValue defaultChecked />
                                    <label className="form-check-label" htmlFor="customCheck1">All
                                        <span className="ms-2">(32)</span>
                                    </label>
                                </div>
                                {/* Single Checkbox*/}
                                <div className="form-check mb-2">
                                    <input className="form-check-input" id="customCheck2" type="checkbox" defaultValue />
                                    <label className="form-check-label" htmlFor="customCheck2">Offers
                                        <span className="ms-2">(46)</span>
                                    </label>
                                </div>
                                {/* Single Checkbox*/}
                                <div className="form-check mb-2">
                                    <input className="form-check-input" id="customCheck3" type="checkbox" defaultValue />
                                    <label className="form-check-label" htmlFor="customCheck3">Profiles
                                        <span className="ms-2">(13)</span>
                                    </label>
                                </div>
                                {/* Single Checkbox*/}
                                <div className="form-check mb-2">
                                    <input className="form-check-input" id="customCheck4" type="checkbox" defaultValue defaultChecked />
                                    <label className="form-check-label" htmlFor="customCheck4">Tests
                                        <span className="ms-2">(68)</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Single Widget*/}
                        <div className="shop-widget mb-4 mb-lg-5 col-12 col-sm-12 col-md-3">
                            {/* <h5 className="widget-title mb-4"></h5> */}
                            {/* Description*/}
                            <div className="widget-desc">

                                {/* Single input*/}
                                <form onSubmit={e => { e.preventDefault(); }} >
                                    <div className="form-group mb-3 col-12">
                                        <input className="form-control" type="Text" placeholder="Search..." />
                                    </div>
                                </form>

                            </div>
                        </div>

                        {/* Single Widget*/}
                        <div className="shop-widget mb-4 mb-lg-5 col-12 col-sm-12 col-md-3">
                            {/* <h5 className="widget-title mb-4">Filter by Disease</h5> */}
                            {/* Description*/}
                            <div className="widget-desc">
                                {/* Single Checkbox*/}
                                <SelectDropdown
                                    title="Disease"
                                    diseaseList={disease}
                                />
                            </div>
                        </div>

                    </div>
                    {/* </div> */}

                    <div className="col-12 col-sm-12 col-md-12">
                        <div className="row g-4 g-lg-5">
                            {shopLists}
                        </div>

                        {/* Pagination Area*/}
                        <div className="saasbox-pagination-area mt-5">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center mb-0">
                                    <li className="page-item active">
                                        <Link className="page-link" to="#">1</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#">2</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#">4</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#">...</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#">9</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
            {/* </div> */}

            <Divider />

            {/* <CtaThree
                title="Be a Partner With Us."
                btnUrl="/"
                btnText="Join Us"
            /> */}

            <Footer
                footerLogo="assets/img/core-img/new/VerdureWayLogoFinal.png"
                footerStyle="footer-2"
            />
        </div>
    )
}