import React, { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import VerifyOtp from '../Modal/VerifyOtp';

export default function RegisterForm() {
    const childRef = useRef(null);

    const handleClick = () => {
        childRef.current.handleShow();
    };

    const handleVerify = () => {
        childRef.current.handleClose();
    };

    return (
        <div className="register-form my-5">
            <VerifyOtp ref={childRef}
                heading="Verify OTP"
                label="OTP"
                placeholder="Enter OTP"
                button="Send OTP"
                handleVerify={handleVerify}
            />
            {/* Register Form */}
            <form onSubmit={e => { e.preventDefault(); }} >
                <div className="row">
                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-4">
                        {/* <label className="mb-2" htmlFor="country">First Name</label> */}
                        <input className="form-control" type="text" name="fName" placeholder="First Name" required />
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-4">
                        {/* <label className="mb-2" htmlFor="country">Middle Name</label> */}
                        <input className="form-control" type="text" name="mName" placeholder="Middle Name" required />
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-4">
                        {/* <label className="mb-2" htmlFor="country">Last Name</label> */}
                        <input className="form-control" type="text" name="lName" placeholder="Last Name" required />
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-6">
                        {/* <label className="mb-2" htmlFor="country">Email Address</label> */}
                        <input className="form-control" type="email" placeholder="Email Address" required />
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-6">
                        {/* <label className="mb-2" htmlFor="country">Mobile No</label> */}
                        <input className="form-control" type="phone" name="mobileNo" placeholder="Mobile No" required />
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-6">
                        {/* <label className="mb-2" htmlFor="country">Gender</label> */}
                        <label className="mb-2" htmlFor="country">Gender</label>
                        <div className="row">
                            <div className="col-6">
                                <Form.Check
                                    inline
                                    label="Male"
                                    name="group1"
                                    type="radio"
                                    id="male"

                                />
                            </div>
                            <div className="col-6">
                                <Form.Check
                                    inline
                                    label="Female"
                                    name="group1"
                                    type="radio"
                                    id="female"
                                    className="col-6"
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-group mb-3 col-12 col-sm-10 col-md-4 col-lg-4">
                        <label className="mb-2" htmlFor="line1">Address</label>
                        <input className="form-control" type="text" name="line1" placeholder="Line 1" required />
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-4 col-lg-4">
                        <label className="mb-2" htmlFor="line2">Address</label>
                        <input className="form-control" type="text" name="line2" placeholder="Line 2" required />
                    </div> */}

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-6">
                        {/* <label className="mb-2" htmlFor="registerPassword">Password</label> */}
                        <input className="form-control" id="registerPassword" type="password" name="password" placeholder="Password" required />
                    </div>
                    {/* 
                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-4">
                        <label className="mb-2" htmlFor="pin">Pin</label>
                        <input className="form-control" type="number" name="pin" placeholder="Pin" required />
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-4">
                        <label className="mb-2" htmlFor="city">City</label>
                        <select className="form-select form-control w-100 mb-30" id="city">
                            <option value="usa">Select City</option>
                        </select>
                    </div>

                    <div className="form-group mb-3 col-12 col-sm-10 col-md-6 col-lg-4">
                        <label className="mb-2" htmlFor="state">State</label>
                        <select className="form-select form-control w-100 mb-30" id="state">
                            <option value="usa">Select State</option>
                        </select>
                    </div> */}

                    <button className="btn btn-primary w-100" type="submit" onClick={handleClick}>Register Now</button>
                </div>

            </form>
        </div>
    )
}