import React from 'react';

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import SectionHeading from "../heading/HeadingOne";
import ShopData from "../../data/shop/shop-data.json";
import OfferCard from './OfferCard';

export default function Offer() {

    const offerSlideSettings = {
        items: 3,
        gutter: 48,
        slideBy: 1,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 5000,
        speed: 750,
        loop: true,
        mouseDrag: true,
        controls: false,
        navPosition: 'bottom',
        autoHeight: true,
        responsive: {
            320: {
                items: 1
            },
            480: {
                items: 2,
                gutter: 24
            },
            768: {
                gutter: 30
            },
            992: {
                items: 3,
                gutter: 30
            },
            1200: {
                items: 3,
                gutter: 48
            }
        }
    };

    const OfferLists = ShopData.map((elem, index) => (
        <OfferCard
            productColumn="col-12 col-md-6"
            key={index}
            id={elem.id}
            productImage={elem.productImage}
            favIcon={elem.favIcon}
            productName={elem.productName}
            salePrice={elem.salePrice}
            oldPrice={elem.oldPrice}
            addToCartIcon={elem.addToCartIcon}
        />
    ))

    return (
        <div className="client-feedback-area bg-gray pt-120 pb-120">
            {/* Section Heading */}
            <SectionHeading
                subtitle="Offers"
                heading="Latest Offers"
                // para="It's crafted with the latest trend of design &amp; coded with all modern approaches. It's a robust &amp; multi-dimensional usable template."
            />

            <div className="container">
                {/* Feedback Slides */}
                <div className="client-feedback-content">
                    <div className="client-feedback-slides">
                        <TinySlider settings={offerSlideSettings}>
                            {OfferLists}
                        </TinySlider>
                    </div>
                </div>
            </div>
        </div>
    )
}