import React from 'react';
import 'tiny-slider/dist/tiny-slider.css';
import ShopData from "../../data/shop/shop-data.json";
import SectionHeadingTwo from "../heading/HeadingTwo";
import PackageCard from './PackageCard';

export default function Package() {

    const packageLists = ShopData.map((elem, index) => (
        <PackageCard
            productColumn="col-12 col-md-4"
            key={index}
            id={elem.id}
            productImage={elem.productImage}
            favIcon={elem.favIcon}
            productName={elem.productName}
            salePrice={elem.salePrice}
            oldPrice={elem.oldPrice}
            addToCartIcon={elem.addToCartIcon}
        />
    ))

    return (
        <div className="saasbox-portfolio-area pt-120 pb-120 bg-gray">
            {/* Section Heading */}
            <SectionHeadingTwo
                subtitle="Packages"
                subtitleColor=""
                heading="Check our latest awesome affordable packages"
                headingColor=""
                para=""
                paraColor=""
                btnColor="btn-primary"
                btnUrl="/portfolio-standard"
                btnText="View All Packges"
            />

            <div className="container">
                <div className="col-12 col-sm-12 col-md-12">
                    <div className="row g-4 g-lg-5">
                        {packageLists}
                    </div>
                </div>
            </div>

        </div>
    )
}